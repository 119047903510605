<template>
    <div>
        <ts-page-title :title="$t('itemOvertime.pageTitle')" :breadcrumb="[
            { text: $t('home'), href: '/' },
            {
                text: $t('itemOvertime.pageTitle'),
                active: true,
            }
        ]" />
        <ts-panel>
            <ts-panel-wrapper class="tw-flex tw-items-center tw-justify-between">
                <div>
                    <Button type="info" ghost @click="addNew">
                        {{ $t('addNew') }}
                    </Button>
                </div>
                <div class="tw-justify-end tw-space-x-2">
                    <Input v-model="search" search :placeholder="$t('itemOvertime.search')" :style="width"
                        @on-focus="() => (width = 'width: 500px')" @on-blur="() => (width = 'width: 300px')" />
                </div>
            </ts-panel-wrapper>
            <Table :columns="columns" size="small" :loading="loading" :data="resources" stripe>
                <template slot-scope="{ row }" slot="oTName">
                    {{ row.ot_name }}
                </template>
                <template slot-scope="{ row }" slot="taxable">
                    <span v-if="row.is_taxable" class="ios-checkmark-circle-outline badge bg-success">
                        Yes
                    </span>
                    <span v-if="!row.is_taxable" class="ios-close-circle-outline badge bg-danger">
                        No
                    </span>
                </template>
                <template slot-scope="{ row }" slot="oTCategory">
                    {{ row.ot_category }}
                </template>
                <template slot-scope="{ row }" slot="rate">
                    {{ row.ot_rate }}
                </template>
                <template slot-scope="{ row }" slot="description">
                    {{ row.description }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a href="#" class="text-primary ml-2" @click.prevent="onEdit(row)" v-tooltip="$t('edit')">
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip confirm :title="$t('areYouSure')" @on-ok="onDelete(row)" :ok-text="$t('yes')"
                        :cancel-text="$t('no')" placement="left" :transfer="true">
                        <a class="ml-2 text-danger" v-tooltip="$t('delete')" :disabled="row._deleting">
                            <i class="fas fa-circle-notch fa-spin" v-if="row._deleting"></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination v-model="pagination" @navigate="fetchData"></ts-i-pagination>
            </div>
        </ts-panel>
        <Modal v-model="showForm" draggable sticky scrollable :mask="false" :footer-hide="true" :z-index="1022"
            :title="$t('itemOvertime.itemOvertime')">
            <FormComponent ref="form_action" @cancel="clearEdit" @fetchData="fetchData" />
        </Modal>
    </div>
</template> 
 
<script>
import FormComponent from './form'
import { mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
    name: 'item-overtime',
    components: {
        FormComponent,
    },
    data() {
        return {
            showForm: false,
            visible: false,
            width: 'width: 300px',
            loading: false
        }
    },
    computed: {
        ...mapState('payroll/itemOvertime', ['resources', 'pagination']),
        search: {
            get() {
                return this.$store.state.payroll.itemOvertime.search;
            },
            set(newValue) {
                this.$store.commit(
                    "payroll/itemOvertime/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "payroll/itemOvertime/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    title: this.$t('itemOvertime.oTName'),
                    slot: 'oTName'
                },
                {
                    title: this.$t('itemOvertime.taxable'),
                    slot: 'taxable'
                },
                {
                    title: this.$t('itemOvertime.oTCategory'),
                    slot: 'oTCategory'
                },
                {
                    title: this.$t('itemOvertime.rate'),
                    slot: 'rate',
                },
                {
                    title: this.$t('itemOvertime.description'),
                    slot: 'description'
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        },
    },
    methods: {
        fetchData(attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/itemOvertime/fetch', attributes)
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onEdit(row) {
            this.showForm = true
            this.$store.commit('payroll/itemOvertime/SET_EDIT_DATA', row)
            this.$refs.form_action.setEditData()
        },
        addNew() {
            this.$refs.form_action.clearInput()
            this.showForm = true
        },
        async onDelete (row){
            row._deleting = true
            this.$store
                .dispatch('payroll/itemOvertime/destroy', row.ot_id)
                .then(response => {
                    this.fetchData()
                    row._deleting = false
                    this.notice({ 
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    row._deleting = false
                })
        },
        clearEdit() {
            this.showForm = false
            this.$store.commit('payroll/itemOvertime/SET_EDIT_DATA', {})
        },
        notice(not) {
            this.$Notice[not.type]({
                title: 'ITEM OVERTIME',
                desc: not.text
            })
        }
    },
        watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>

<style scoped>
</style>