var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('itemOvertime.pageTitle'),"breadcrumb":[
        { text: _vm.$t('home'), href: '/' },
        {
            text: _vm.$t('itemOvertime.pageTitle'),
            active: true,
        }
    ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t('addNew'))+" ")])],1),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('itemOvertime.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('Table',{attrs:{"columns":_vm.columns,"size":"small","loading":_vm.loading,"data":_vm.resources,"stripe":""},scopedSlots:_vm._u([{key:"oTName",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.ot_name)+" ")]}},{key:"taxable",fn:function(ref){
    var row = ref.row;
return [(row.is_taxable)?_c('span',{staticClass:"ios-checkmark-circle-outline badge bg-success"},[_vm._v(" Yes ")]):_vm._e(),(!row.is_taxable)?_c('span',{staticClass:"ios-close-circle-outline badge bg-danger"},[_vm._v(" No ")]):_vm._e()]}},{key:"oTCategory",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.ot_category)+" ")]}},{key:"rate",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.ot_rate)+" ")]}},{key:"description",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.description)+" ")]}},{key:"action",fn:function(ref){
    var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no'),"placement":"left","transfer":true},on:{"on-ok":function($event){return _vm.onDelete(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"title":_vm.$t('itemOvertime.itemOvertime')},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('FormComponent',{ref:"form_action",on:{"cancel":_vm.clearEdit,"fetchData":_vm.fetchData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }